import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './scss/index.min.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollRestoration from 'router/scrollRestoration';

ReactGA.initialize('UA-177093181-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Router>
    <ScrollRestoration>
      <App />
    </ScrollRestoration>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
