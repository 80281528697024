import React from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollLink } from 'components/ScrollLink';

export * from './nav-context';

const navData = [
  {
    title: 'Home',
    hash: '/#home',
  },
  {
    title: 'Mission',
    hash: '/#mission',
  },
  {
    title: 'Portfolio',
    hash: '/#portfolio',
  },
  // {
  //   title: 'Team',
  //   hash: '#team',
  // },
  {
    title: 'Kontakt',
    hash: '/#kontakt',
  },
];

export const Nav = ({ active }) => {
  console.log(active);

  return (
    <nav>
      <div className="row justify-center">
        {navData.map((item) => (
          <ScrollLink
            className={'/#' + active === item.hash ? 'active' : ''}
            key={item.hash}
            to={item.hash}
          >
            <span>{item.title}</span>
          </ScrollLink>
        ))}
      </div>
    </nav>
  );
};
