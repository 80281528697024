import React from 'react';
import { useSpring, animated } from 'react-spring';

const calc = (x, y, p, divisor) => [-y / divisor, x / divisor, p];

const trans = (x, y) => `perspective(2000px) rotateX(${x}deg) rotateY(${y}deg)`;

export const TiltCard = ({
  children,
  className = '',
  perspective = 1000,
  divisor = 20,
  ...props
}) => {
  const [spring, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 1, tension: 350, friction: 50 },
  }));
  return (
    <animated.div
      {...props}
      className={className}
      onMouseMove={({ clientX: x, clientY: y, ...e }) => {
        const { left, width, height, top } = e.target.getBoundingClientRect();
        set({
          xys: calc(
            x - left - width / 2,
            y - top - height / 2,
            perspective,
            divisor
          ),
        });
      }}
      onMouseLeave={() => set({ xys: [0, 0, perspective] })}
      style={{ transform: spring.xys.interpolate(trans) }}
    >
      {children}
    </animated.div>
  );
};

export const TiltCard2 = ({
  children,
  className = '',
  perspective = 1000,
  divisor = 30,
  ...props
}) => {
  const [spring, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 1, tension: 200, friction: 50 },
  }));
  return (
    <div
      {...props}
      className={className}
      onMouseMove={({ clientX: x, clientY: y, ...e }) => {
        const { left, width, height, top } = e.target.getBoundingClientRect();
        set({
          xys: calc(
            x - left - width / 2,
            y - top - height / 2,
            perspective,
            divisor
          ),
        });
      }}
      onMouseLeave={() => set({ xys: [0, 0, perspective] })}
    >
      <animated.div style={{ transform: spring.xys.interpolate(trans) }}>
        {children}
      </animated.div>
    </div>
  );
};
