import React, { createContext, useState, useCallback } from 'react';
import { Nav } from './';

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const [state, setState] = useState('home');
  const value = useCallback({ setNav: setState }, [setState]);

  return (
    <>
      <Nav active={state} />
      <NavContext.Provider value={value}>{children}</NavContext.Provider>
    </>
  );
};
