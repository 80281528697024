import React from 'react';
import { useHistory } from 'react-router-dom';

export const ScrollLink = ({ to, children, ...props }) => {
  const history = useHistory();
  const handleOnClick = (e) => {
    if (to.search('#') === -1) return;
    e.preventDefault();
    const el = document.querySelector(to.replace('/', ''));
    if (!el) return history.push(to);
    el.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  };
  return (
    <a {...props} href={to} onClick={handleOnClick}>
      {children}
    </a>
  );
};
