import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'assets/logo-weiß.png';
import { TiltCard } from 'components/animators';

export const Footer = ({ footerTitle, footerAddress, footerContact }) => {
  return (
    <footer className="footer">
      <div className="card-dark logo__container row justify-center">
        <TiltCard divisor={10}>
          <img className="logo" src={Logo} alt="Spaceadacemy Logo" />
        </TiltCard>
      </div>
      <div className="container row">
        <div className="col-lg-9">
          <h2 className="fw font-secondary no-deco">{footerTitle}</h2>
        </div>
        <div
          className="col-md-4"
          dangerouslySetInnerHTML={{ __html: footerAddress }}
        ></div>
        <div
          className="col-md-4"
          dangerouslySetInnerHTML={{ __html: footerContact }}
        ></div>
        <div className="col-md-4">
          <Link to="Impressum">Impressum</Link>
          <br />
          <Link to="Datenschutz">Datenschutz</Link>
        </div>
      </div>
    </footer>
  );
};
