import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
const ScrollRestoration = ({ children }) => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollRestoration);
