import React, { useEffect, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { NavContext } from 'router';

export const HashSection = ({ id = '', children, ...props }) => {
  const [ref, inView] = useInView({ threshold: 0.6, rootMargin: '200px' });
  const { setNav } = useContext(NavContext);

  useEffect(() => {
    if (!inView) return;
    console.log(id);
    setNav(id);
  }, [inView, id, setNav]);
  return (
    <section id={id} {...props} ref={ref}>
      {children}
    </section>
  );
};
