import React, { useState, useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NavProvider } from './router';

import { API_URL } from 'config';

import axios from 'axios';
import { Footer } from 'router/Footer';

const Home = React.lazy(() => import('./Home'));
const Impressum = React.lazy(() => import('pages/Impressum'));
const Datenschutz = React.lazy(() => import('pages/Datenschutz'));

function App() {
  const [state, setState] = useState(null);

  useEffect(() => {
    Promise.all([
      axios.get(API_URL.singleton('home')),
      axios.get(API_URL.collection('info')),
      axios.get(API_URL.collection('produkte')),
    ])
      .then((res) => {
        const [
          { data: home },
          {
            data: { entries: info },
          },
          {
            data: { entries: produkte },
          },
        ] = res;
        setState({ home, produkte, info });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <NavProvider>
      {/* <ScrollToHash /> */}
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/"
            component={() =>
              state ? <Home setState={setState} state={state} /> : null
            }
          />
          {/* <ProtectedRoute exact path="/novels" component={Home} /> */}

          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/datenschutz" component={Datenschutz} />
        </Switch>
        {state && <Footer {...state.home} />}
      </Suspense>
    </NavProvider>
  );
}

export default App;
